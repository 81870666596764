body {
  width: max-content;
  height: max-content;
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #272727;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  position: relative;
  min-width: 1000px;
  justify-content: center;
  align-items: center;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
  font-family: "Wallpoet";
  src: url("/src/assets/fonts/Wallpoet-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Monoton";
  src: url("/src/assets/fonts/Monoton-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("/src/assets/fonts/Montserrat-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url("/src/assets/fonts/MaterialIconsOutlined-Regular.otf") format("otf");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}
